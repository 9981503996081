<template>
  <div class="body">
    <div :class="{ 'shadow-bar': scrollPosition > 0, header: scrollPosition == 0 }">
      <div class="header-container">
        <router-link to="/">
          <img
            class="logo-bracesco"
            src="@/assets/logo-bracesco-inversiones.svg"
            alt="Logo bracesco inversiones"
          />
        </router-link>
        <div class="links">
          <router-link to="/sobreNosotros"
            ><a class="a-header" href="#0" :underline="false"
              >Sobre nosotros</a
            ></router-link
          >
          <a
            class="a-header"
            href="#0"
            @click="dialogFormContactoVisible = true"
            :underline="false"
            >Contacto</a
          >
        </div>
      </div>
      <!-- hambuerger -->
      <nav class="hambuerger" role="navigation">
        <div id="menuToggle">
          <!--
    A fake / hidden checkbox is used as click reciever,
    so you can use the :checked selector on it.
    -->
          <input type="checkbox" />

          <!--
    Some spans to act as a hamburger.
    
    They are acting like a real hamburger,
    not that McDonalds stuff.
    -->
          <span></span>
          <span></span>
          <span></span>

          <!--
    Too bad the menu has to be inside of the button
    but hey, it's pure CSS magic.
    -->
          <ul id="menu">
            <a class="a-header" href="#0" :underline="false">Sobre nosotros</a>
            <a
              class="a-header"
              href="#0"
              @click="dialogFormContactoVisible = true"
              :underline="false"
              >Contacto</a
            >
          </ul>
        </div>
      </nav>
    </div>
    <div class="hero">
      <!-- The video -->
      <video class="video" autoplay muted loop id="myVideo">
        <source src="../assets/about/portada.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="hero-image">
      <div class="card-inversiones">
        <img
          src="@/assets/about/logo-portada.svg"
          class="logo-portada"
          alt="Logo bracesco inversiones"
        />
        <div class="container-logos">
          <div class="logos">
            <img src="@/assets/about/logoSimple.svg" alt="Logo Simple" />
          </div>
          <div class="logos">
            <img src="@/assets/about/logoEureka.svg" alt="Logo Eureka" />
          </div>
          <div class="logos">
            <img src="@/assets/about/logoBracesco.svg" alt="Logo Bracesco Inmuebles" />
          </div>
          <div class="logos">
            <img
              src="@/assets/about/logoCordobaTurismo.svg"
              alt="Logo Cordoba Inversiones Turismo"
            />
          </div>
          <div class="logos">
            <img
              src="@/assets/about/logoCordobaInmuebles.svg"
              alt="Logo Cordoba Inversiones Inmuebles"
            />
          </div>
          <div class="logos-last">
            <img
              src="@/assets/about/logoCordobaCafe.svg"
              alt="Logo Cordoba Inversiones Café"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="card-empezar">
        <p>
          Somos un grupo de empresas dedicadas a ofrecer servicios de alta calidad y
          profesionalismo.
        </p>
        <p>
          Poseemos una amplia trayectoria que nos permite ofrecer nuestros servicios a
          nivel internacional con presencia en el continente americano y europeo.
        </p>
        <p>
          Cada una de nuestras empresas esta formada por profesionales de gran experiencia
          que, con tecnología vanguardista, llevan adelante de manera excepcional cada
          proyecto.
        </p>
      </div>
    </div>
    <div class="first-container-cards">
      <div class="container-cards">
        <div class="red-card">
          <div class="svg-icon-simple">
            <img src="@/assets/about/cards/cardSimple.png" alt="Logo simple" />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              SIMPLE te permite vender tu inmueble en menos tiempo, de particular a
              particular, sin comisiones de agencia, de forma 100% digital y dinámica.
            </p>
            <p
              style="
                padding-left: 20px;
                padding-right: 20px;
                text-align: center;
                font-weight: 600;
              "
            >
              ¡Vender ahora es SIMPLE!
            </p>
          </div>
          <a href="https://simpleproptech.com" target="_blank" class="button-card">
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div class="red-card">
          <div class="svg-icon-bracesco">
            <img src="@/assets/about/cards/logo-bracescoes.png" alt="Logo Bracesco.es" />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              BRACESCO International Real Estate especializada en la comercialización de inmuebles únicos
              y exclusivos, propiedades residenciales de alto standing, inmuebles
              comerciales y activos inmobiliarios de inversión.
            </p>
          </div>
          <a href="https://bracesco.es" target="_blank" class="button-card">
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div class="red-card">
          <div class="svg-icon-eureka">
            <img src="@/assets/about/cards/logo-eureka.png" alt="Logo Eureka" />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              Nos dedicamos a realizar anuncios profesionales, para personas
              independientes que comercializan sus bienes de manera personal, sin
              intermediarios.
            </p>
          </div>
          <a
            href="https://www.facebook.com/eurekaonlinemkt/"
            target="_blank"
            class="button-card"
          >
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="first-container-cards">
      <div class="container-cards" style="position: relative; margin-top: 48px">
        <div class="red-card">
          <div class="svg-icon-turismo">
            <img
              src="@/assets/about/cards/cba-inversiones-turismo.png"
              alt="Logo CBA Inversiones Turismo"
            />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              El mundo al alcance de su mano. Atención personalizada, asesoramiento y un
              extenso conocimiento sobre las mejores plazas turísticas nos convierten en
              la opción ideal para planificar y concretar el viaje de sus sueños.
            </p>
          </div>
          <a
            href="http://www.cordobainversiones.com/"
            target="_blank"
            class="button-card"
          >
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div class="red-card">
          <div class="svg-icon-inmuebles">
            <img
              src="@/assets/about/cards/cba-inversiones-inmuebles.png"
              alt="Logo CBA Inversiones Inmuebles"
            />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              Experiencia, planificación y visión. Ofrecemos la más amplia gama de
              servicios inmobiliarios. La administración de propiedades, alquileres,
              ventas y desarrollos de emprendimientos, nos convierten en un socio de
              incalculable valor para sus inversiones y proyectos.
            </p>
          </div>
          <a
            href="http://www.cordobainversiones.com/"
            target="_blank"
            class="button-card"
          >
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div class="red-card">
          <div class="svg-icon-cafe">
            <img
              src="@/assets/about/cards/cba-inversiones-cafe.png"
              alt="Logo CBA Inversiones Cafe"
            />
          </div>
          <div>
            <p style="padding: 20px 20px 0px 20px">
              Un lugar de encuentro, de buen gusto, de búsqueda de los más delicados
              sabores. Ofrecemos un espacio cómodo y tranquilo, acompañado de productos de
              primera calidad.
            </p>
          </div>
          <a
            href="http://www.cordobainversiones.com/"
            target="_blank"
            class="button-card"
          >
            Visitar página
            <svg
              style="margin-left: 10px"
              width="13"
              height="10"
              viewBox="0 0 13 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.19528 1.18912C8.94074 0.935979 8.52919 0.937106 8.27604 1.19164C8.0229 1.44618 8.02402 1.85773 8.27856 2.11088L10.7755 4.5942L0.65 4.5942C0.291015 4.5942 0 4.88522 0 5.2442C0 5.60319 0.291015 5.8942 0.65 5.8942L10.7743 5.8942L8.27845 8.37763C8.02397 8.63084 8.02295 9.04239 8.27615 9.29686C8.52936 9.55134 8.94092 9.55237 9.19539 9.29916L12.8085 5.70409C12.9311 5.58208 13 5.41622 13 5.24324C13 5.07026 12.931 4.90443 12.8083 4.78245L9.19528 1.18912Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="13" height="9" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="section-offer"></div>

    <div class="section-footer">
      <h2 class="title-section-footer">
        Únase a nosotros y experimente una mejor <br />
        manera de invertir en bienes raices
      </h2>
      <el-button
        class="button-inversor"
        @click="dialogFormVisible = true"
        style="margin: 0px"
        >CONVIÉRTASE EN INVERSOR</el-button
      >
    </div>
    <div class="footerContainer">
    <div class="footer footerTop">
      <div class="logo-terminos">
        <img
          class="logo-bracesco"
          src="@/assets/logo-bracesco-inversiones.svg"
          alt="Logo bracesco inversiones"
        />
      </div>
      <div class="logo-tawa">
        <div style="min-width: 320px">
          <div class="info-contacto">
            <div class="svg-contacto">
              <svg
                width="20"
                height="20"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.625 14.5835C30.625 24.7918 17.5 33.5418 17.5 33.5418C17.5 33.5418 4.375 24.7918 4.375 14.5835C4.375 11.1025 5.75781 7.76413 8.21922 5.30272C10.6806 2.8413 14.019 1.4585 17.5 1.4585C20.981 1.4585 24.3194 2.8413 26.7808 5.30272C29.2422 7.76413 30.625 11.1025 30.625 14.5835Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.5 18.9585C19.9162 18.9585 21.875 16.9997 21.875 14.5835C21.875 12.1673 19.9162 10.2085 17.5 10.2085C15.0838 10.2085 13.125 12.1673 13.125 14.5835C13.125 16.9997 15.0838 18.9585 17.5 18.9585Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>Paseo de la Castellana 79 P7 Madrid</div>
          </div>
          <div class="info-contacto">
            <div class="svg-contacto">
              <svg
                width="20"
                height="20"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0833 24.675V29.05C32.0849 29.4561 32.0017 29.8581 31.839 30.2303C31.6763 30.6024 31.4377 30.9365 31.1384 31.211C30.8391 31.4856 30.4858 31.6946 30.101 31.8248C29.7163 31.9549 29.3086 32.0032 28.9041 31.9666C24.4166 31.479 20.106 29.9456 16.3187 27.4896C12.7951 25.2505 9.80773 22.2631 7.56869 18.7396C5.10408 14.9351 3.5703 10.6035 3.09161 6.09581C3.05517 5.69253 3.10309 5.28609 3.23234 4.90235C3.36159 4.51861 3.56932 4.16598 3.84231 3.86693C4.1153 3.56787 4.44758 3.32893 4.81797 3.16532C5.18837 3.00172 5.58877 2.91703 5.99369 2.91665H10.3687C11.0764 2.90968 11.7626 3.1603 12.2992 3.6218C12.8358 4.08329 13.1863 4.72417 13.2854 5.42498C13.47 6.82507 13.8125 8.19979 14.3062 9.52289C14.5024 10.0449 14.5449 10.6121 14.4286 11.1575C14.3122 11.7029 14.042 12.2035 13.6499 12.6L11.7979 14.4521C13.8739 18.1031 16.8969 21.126 20.5479 23.2021L22.3999 21.35C22.7965 20.9579 23.297 20.6877 23.8424 20.5714C24.3878 20.4551 24.9551 20.4975 25.477 20.6937C26.8001 21.1874 28.1749 21.5299 29.5749 21.7146C30.2834 21.8145 30.9303 22.1713 31.3928 22.7172C31.8553 23.263 32.101 23.9598 32.0833 24.675Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div><a href="tel:919049232">Tel. 919049232</a></div>
          </div>
          <div class="info-contacto">
            <div class="svg-contacto">
              <svg
                width="20"
                height="20"
                viewBox="0 0 35 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.83335 5.8335H29.1667C30.7709 5.8335 32.0834 7.146 32.0834 8.75016V26.2502C32.0834 27.8543 30.7709 29.1668 29.1667 29.1668H5.83335C4.22919 29.1668 2.91669 27.8543 2.91669 26.2502V8.75016C2.91669 7.146 4.22919 5.8335 5.83335 5.8335Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32.0834 8.75L17.5 18.9583L2.91669 8.75"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div>
              <a href="mailto:info@bracesco.es">info@bracesco.es</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="footerContainer">
    <div class="footer">
      <p class="logo-terminos p2-desktop">
        <a class="a-terminos" href="#0">Términos y condiciones</a> ·
        <a class="a-atribuciones" href="#0"> Atribuciones</a>
      </p>
      <div class="logo-tawa">
        <p class="p2 poweredBy" style="margin-right: 18px; margin-bottom: 0">
          Desarrollado por
        </p>
        <a class="logoTawa" href="https://somostawa.com.ar/"
          ><img style="width: 45px;" src="@/assets/logo-tawa-blanco.svg" alt="Logo Tawa"
        /></a>
      </div>
    </div>
    </div>
  </div>

  <el-dialog title="Conviértase en Inversor" v-model="dialogFormVisible">
    <el-form :model="form" class="form" ref="form">
      <el-form-item
        prop="name"
        required
        :rules="[
          {
            required: true,
            message: 'Por favor inserte nombre y apellido',
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          v-model="form.name"
          placeholder="Nombre y Apellido *"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        :rules="[
          { required: true, message: 'Por favor inserte email', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ]"
        prop="email"
        required
      >
        <el-input
          v-model="form.email"
          type="email"
          placeholder="Email *"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item required>
        <el-col :span="11">
          <el-form-item
            prop="prefijo"
            required
            :rules="[
              { required: true, message: 'Por favor inserte prefijo' },
              { type: 'number', message: 'Debe ser un numero' },
            ]"
          >
            <el-input
              v-model.number="form.prefijo"
              placeholder="Prefijo *"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col class="line" :span="2">-</el-col>
        <el-col :span="12">
          <el-form-item
            prop="telefono"
            required
            :rules="[
              { required: true, message: 'Por favor inserte teléfono' },
              { type: 'number', message: 'Debe ser un numero' },
            ]"
          >
            <el-input
              v-model.number="form.telefono"
              placeholder="Teléfono *"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        required
        prop="tipoInversor"
        :rules="[{ required: true, message: 'Por favor seleccione tipo de inversor' }]"
      >
        <el-select v-model="form.tipoInversor" placeholder="Tipo de inversor *">
          <el-option
            label="¿Compra para reformar y vender?"
            value="¿Compra para reformar y vender?"
          ></el-option>
          <el-option
            label="¿Le interesa una renta fija anual?"
            value="¿Le interesa una renta fija anual?"
          ></el-option>
          <el-option
            label="¿Compra para reformar y alquilar?"
            value="¿Compra para reformar y alquilar?"
          ></el-option>
          <el-option
            label="¿Le interesan activos inmobiliarios de mediano plazo? (construccion para venta o alquiler)"
            value="¿Le interesan activos inmobiliarios de mediano plazo? (construccion para venta o alquiler)"
          ></el-option>
        </el-select>
      </el-form-item>
      <span style="word-break: normal"
        >¿Ha realizado alguna inversión inmobiliaria alguna vez?</span
      >
      <el-form-item>
        <el-radio-group required prop="inversion" v-model="form.inversion">
          <el-radio label="Si"></el-radio>
          <el-radio label="No"></el-radio>
        </el-radio-group>
      </el-form-item>

      <span style="word-break: normal"
        >¿Aproximadamente cuánto consideraría invertir?</span
      >
      <div style="display: flex">
        <el-form-item
          style="width: 30%; margin-right: 10px"
          required
          prop="moneda"
          :rules="[{ required: true, message: 'Por favor seleccione moneda' }]"
        >
          <el-select v-model="form.moneda" placeholder="Moneda *">
            <el-option label="EUR" value="EUR"></el-option>
            <el-option label="USD" value="USD"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          required
          prop="cash"
          :rules="[
            { required: true, message: 'Por favor seleccione inversón aproximada' },
            { type: 'number', message: 'Debe ser un numero' },
          ]"
        >
          <el-input
            v-model.number="form.cash"
            placeholder="$"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <div class="form-footer">
        <el-button class="button-form" @click="validFormInversor()">ENVIAR</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog title="Contacto" v-model="dialogFormContactoVisible">
    <el-form :model="formContacto" ref="formContacto" class="form">
      <el-form-item
        prop="name"
        required
        :rules="[
          {
            required: true,
            message: 'Por favor inserte nombre y apellido',
            trigger: 'blur',
          },
        ]"
      >
        <el-input
          v-model="formContacto.name"
          placeholder="Nombre y Apellido *"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item
        :rules="[
          { required: true, message: 'Por favor inserte email', trigger: 'blur' },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ]"
        prop="email"
        required
      >
        <el-input
          v-model="formContacto.email"
          type="email"
          placeholder="Email *"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          placeholder="Mensaje"
          v-model="formContacto.mensaje"
        ></el-input>
      </el-form-item>
      <el-form-item
        :rules="[
          {
            required: true,
            message: 'Por favor acepte el aviso legal y política de privacidad',
            trigger: 'blur',
          },
        ]"
        prop="terminos"
        required
      >
        <el-checkbox-group v-model="formContacto.terminos">
          <el-checkbox name="type"></el-checkbox>
          <p>He leído, comprendo y acepto el aviso legal y política de privacidad</p>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="form-footer">
        <el-button class="button-form" @click="validFormContacto()">ENVIAR</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
//import 'firebase/database'; // If using Firebase database
import { getDatabase, ref, set } from "firebase/database";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      scrollPosition: 0,
      dialogFormVisible: false,
      dialogFormContactoVisible: false,
      form: {
        name: "",
        email: "",
        prefijo: "",
        telefono: "",
        tipoInversor: "",
        inversion: "Si",
        moneda: "EUR",
        cash: "",
      },
      formLabelWidth: "120px",

      formContacto: {
        name: "",
        email: "",
        terminos: [],
        mensaje: "",
      },
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    validFormInversor() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          console.log("entro form valido");
          this.sendEmailNotificationFormInversor();
          this.dialogFormVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    validFormContacto() {
      this.$refs["formContacto"].validate((valid) => {
        console.log(this.terminos, " muestro terminos");
        if (valid) {
          console.log("entro form valido");
          this.sendEmailNotificationFormContacto();
          this.dialogFormContactoVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    sendEmailNotificationFormInversor() {
      let idUnique = this.generateId();
      const emailId = idUnique;
      const updates = {};
      const newEmailInformate = {
        name: this.form.name,
        email: this.form.email,
        prefijo: this.form.prefijo,
        telefono: this.form.telefono,
        tipoInversor: this.form.tipoInversor,
        inversion: this.form.inversion,
        moneda: this.form.moneda,
        cash: this.form.cash,
        fecha: Date().toLocaleString(),
        pais: "España",
        tipo: "Ser Inversor",
      };
      updates[`emails/${emailId}`] = newEmailInformate;
      const db = getDatabase();
      //enviamos mail ahora
      set(ref(db, "emails/" + idUnique), newEmailInformate)
        .then(() => {
          console.log("se envio exitosamente!");
          //vaciamos formulario
          this.$refs["form"].resetFields();
          this.$message({
            dangerouslyUseHTMLString: true,
            message: "<p>¡Formulario enviado con exito!</p>",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendEmailNotificationFormContacto() {
      console.log("entro a contacto");
      let idUnique = this.generateId();
      const emailId = idUnique;
      const updates = {};
      const newEmailInformate = {
        name: this.formContacto.name,
        email: this.formContacto.email,
        mensaje: this.formContacto.mensaje,
        fecha: Date().toLocaleString(),
        pais: "España",
        tipo: "Contacto",
      };
      updates[`emails/${emailId}`] = newEmailInformate;
      const db = getDatabase();
      //enviamos mail ahora
      console.log("por enviar email");
      set(ref(db, "emails/" + idUnique), newEmailInformate)
        .then(() => {
          console.log("se envio exitosamente!");
          //vaciamos formulario
          this.$refs["formContacto"].resetFields();
          this.$message({
            dangerouslyUseHTMLString: true,
            message: "<p>¡Formulario enviado con exito!</p>",
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateId() {
      // Modeled after base64 web-safe chars, but ordered by ASCII.
      console.log("generate Id:");
      var PUSH_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzfc";

      // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
      var lastPushTime = 0;

      // We generate 72-bits of randomness which get turned into 12 characters and appended to the
      // timestamp to prevent collisions with other clients.  We store the last characters we
      // generated because in the event of a collision, we'll use those same characters except
      // "incremented" by one.
      var lastRandChars = [];

      var now = new Date().getTime();
      var duplicateTime = now === lastPushTime;
      lastPushTime = now;

      var timeStampChars = new Array(8);
      for (var i = 7; i >= 0; i--) {
        timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
        // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
        now = Math.floor(now / 64);
      }
      if (now !== 0) throw new Error("We should have converted the entire timestamp.");

      var id = timeStampChars.join("");

      if (!duplicateTime) {
        for (i = 0; i < 12; i++) {
          lastRandChars[i] = Math.floor(Math.random() * 64);
        }
      } else {
        // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
        for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
          lastRandChars[i] = 0;
        }
        lastRandChars[i]++;
      }
      for (i = 0; i < 12; i++) {
        id += PUSH_CHARS.charAt(lastRandChars[i]);
      }
      if (id.length != 20) throw new Error("Length should be 20.");
      return id;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button-card {
  width: 153px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  /* Secondary inv */
  position: absolute;
  right: 0;
  bottom: 0;
  background: #f8253e;
  border-radius: 10px 0px;
  color: white;
  transition: 0.25s;
}
.button-card:hover {
  transition: 0.25s;
  width: 153px;
  height: 40px;
  box-shadow: 0 2px 4px darkgray;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  /* Secondary inv */
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ff4d62;
  border-radius: 10px 0px;
  color: white;
}
.video {
  width: 100%;
  object-fit: cover;
  height: 100%;
  filter: grayscale(1) brightness(0.25);
  -webkit-filter: grayscale(1);
  filter: grayscale(100%) brightness(0.25);
}
.header {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0%;
  z-index: 40;
  background-color: transparent;
}

.shadow-bar {
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0%;
  z-index: 40;
  background-color: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
  margin: 0 auto;
  height: 80px;
  max-width: 1366px;
}

.links {
  display: flex;
  justify-content: space-around;
  min-width: 266px;
}
.body {
  background-color: white;
}
.hero {
  height: 768px;
  z-index: 100;
}

.hero-image {
  height: 768px;
  position: absolute;
  z-index: 1;
  top: 0px;
  background-repeat: no-repeat;
  background-position: right bottom;
  width: 100%;
  background-size: contain;
  justify-content: center;
  display: flex;
}

.card-inversiones {
  display: flex;
  flex-direction: column;
  text-align: left;
  z-index: 4;
  max-width: 1366px;
  margin: 0 auto;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.card-title {
  color: white;
  text-align: left;
}

.logo-portada {
  max-width: 728.46px;
  height: 42.58px;
}
.info-contacto {
  padding-bottom: 20px;
}

@media (min-width: 1500px) {
  .card-inversiones {
    padding-left: 0px;
  }
}

.button-inversor {
  width: 312px;
  max-width: 312px;
  height: 51px;
  background-color: #f8253e;
  padding: 16px 37px 16px 37px;
  border-radius: 0px;
  border: none;
  color: white;
  transition: 0.25s;
  margin-top: 48px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.button-form {
  background-color: #f8253e;
  padding: 16px 37px 16px 37px;
  border-radius: 0px;
  border: none;
  color: white;
  transition: 0.25s;
  margin-left: 24px;
  margin-top: 48px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}
.button-inversor:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: #f8253eba;
}
.section {
  height: 501px;
  background-color: #333333;
  background-image: url("../assets/ilustraciones.png");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  background-size: 80%;
  background-position: center bottom;
}
.section-offer {
  height: 427px;
  background-color: white;
}

.title-section-offer {
  padding-top: 300px;
}

h5 {
  padding-top: 60px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
  margin-bottom: 36px;
}
h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}
h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 0;
}
h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  align-items: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 52px;
  margin-bottom: 20px;
}
.card-empezar {
  height: 322px;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 80px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", sans-serif;
  text-align: left;
}
a {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  text-align: left;
  color: white;
  text-decoration: none;
}
a:hover {
  color: #f8253e;
}
.a-header {
  color: #bdbdbd;
}
.a-header:hover {
  color: #f8253e;
}
.p2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  text-align: left;
  color: white;
  text-decoration: none;
  margin-left: 24px;
}
span {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: "Inter", sans-serif;
  text-align: left;
}
.red-card {
  max-width: 381px;
  width: 30%;
  height: 493px;
  background-color: white;
  box-shadow: 0px 0px 70px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  color: black;
  position: relative;
}
.section-offer {
  height: 427px;
}

.first-container-cards {
  position: relative;
  max-width: 1366px;
  margin: 0 auto;
}

.container-cards {
  display: flex;
  position: absolute;
  bottom: -365.5px;
  justify-content: space-around;
  width: 100%;
  z-index: 20;
}
.svg-icon-simple {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/cardBackgroundSimple.png");
}

.svg-icon-bracesco {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/background-bracescoes.png");
}

.svg-icon-eureka {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/background-eureka.png");
}

.svg-icon-turismo {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/background-turismo.png");
}

.svg-icon-inmuebles {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/background-inmuebles.png");
}

.svg-icon-cafe {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/about/cards/background-cafe.png");
}
.section-footer {
  min-height: 460px;
  background-image: url("../assets/arbustos-footer.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  background-position: center bottom;
  background-size: 100% 90%;
  background-color: #333333;
}
.footer {
  display: flex;
  justify-content: space-between;
  background-color: #333333;
  max-width: 1366px;
  margin: 0 auto;
}

.title-section-footer {
  color: white;
  margin-bottom: 64px;
  padding-top: 100px;
}
.logo-terminos {
  align-items: center;
    margin-left: 79px !important;
}
.logo-tawa {
  display: flex;
  align-items: center;
  margin-right: 79px;
}
.form {
  text-align: left;
  max-width: 595px;
  width: 60%;
  padding: 0px 100px;
}
.form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hambuerger {
  display: none;
}

#menuToggle {
  display: block;
  position: absolute;
  top: 28px;
  right: 30px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 26px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 0;
  padding: 50px;
  padding-top: 125px;
  right: -100px;
  display: grid;
  height: 100vh;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  grid-template: repeat(20, 1fr) / 130px;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
  color: rgb(248, 37, 62);
}

/*
 * And let's fade it in from the left
 */
#menuToggle input:checked ~ ul {
  transform: none;
  opacity: 1;
}

/* @media screen and (max-width: 768px) {
  #menu {
    transform: none;
    opacity: 0;

    transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }
} */
.container-logos {
  margin-top: 135px;
}

.logos {
  margin-right: 60px;
}
.logos-last {
  margin-right: 0px;
}
.container-logos {
  display: flex;
}
.info-contacto {
   display: grid;
  color: white;
  grid-template-columns: 10% 90%;
  text-align: left;
}
@media (max-width: 1366px) {
  .logos {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .logos-last {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .container-logos {
    display: grid;
    margin-top: 135px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px;
  }
  .hero {
    height: 500px;
  }

  .hero-image {
    height: 500px;
  }

  .card-inversiones {
    padding-top: 70px;
  }

  .section {
    height: 600px;
  }

  .section-footer {
    height: 300px;
  }
  .hambuerger {
    display: none;
  }
}

@media (max-width: 1024px) {
  .hero {
    height: 500px;
  }

  .logo-bracesco {
    width: 200px;
  }

  .container-logos {
    display: grid;
    margin-top: 80px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
  }
  .hero-image {
    height: 500px;
    background-position: 400px bottom;
  }

  .card-inversiones {
    padding-top: 70px;
    padding: 0;
    margin-top: 20px;
  }

  .logo-portada {
    max-width: 500px;
    height: 42.58px;
    padding-top: 40px;
  }

  .section {
    height: 600px;
  }

  .section-footer {
    height: 300px;
  }

  h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin-top: 0;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin-top: 0;
  }
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin-top: 52px;
    margin-bottom: 20px;
  }

  h5 {
    padding-top: 60px;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
    margin-top: 0;
    margin-bottom: 36px;
  }

  .hambuerger {
    display: none;
  }
}
.p2-mobile {
  display: none;
}
.p2-desktop {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  text-align: left;
  color: white;
  text-decoration: none;
  margin-left: 24px;
}
.footerTop{
  padding-top: 30px; 
  padding-bottom: 70px;
}

.footerContainer{
  background: #333333;
}
@media (max-width: 840px) {
.a-header{
  color:black;
}
.footerTop{
padding-top: 30px; 
padding-bottom: 20px
}
.a-terminos{
 padding-right:5px; 
}
.a-atribuciones{
 padding-left:5px; 
}
  .p2-mobile {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: "Inter", sans-serif;
    text-align: left;
    color: white;
    text-decoration: none;
    margin-left: 24px;
    display: inline-block;
  }
  .p2-desktop {
    display: none;
  }
  .form {
    text-align: left;
    max-width: 595px;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .logo-tawa {
    margin-right: 17px;
    text-align: center;
    display:grid;
  }
  .hambuerger {
    display: block;
  }
  .links {
    display: none;
  }
  .header-container {
    padding-left: 15px;
  }

  .hero {
    background: linear-gradient(#333333 90%, white 10%);
  }

  .hero-image {
    background-position: right bottom;
    background-size: 70%;
    height: 500px;
  }

  .card-title {
    text-align: center;
  }

  .card-inversiones {
    padding-top: 70px;
    text-align: center !important;
    padding: 0;
    margin-top: 20px;
  }

  .logo-portada {
    display: none;
  }

  .container-logos {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 40px;
  }

  .logos {
    margin: 0 auto;
  }

  .card-empezar {
    height: 350px;
  }

  .section {
    height: none;
  }

  .first-container-cards {
    background-color: white;
  }

  .container-cards {
    display: block;
    position: relative;
    background-color: white;
    bottom: 0;
    padding-top: 40px;
    z-index: 20;
  }

  .red-card {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
    position: relative;
  }

  .button-card {
    height: 20px;
  }

  .section-offer {
    display: none;
  }

  .title-section-offer {
    padding: 0px 20px;
  }

  .title-section-footer {
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .section-footer {
    background-size: contain;
    height: 400px;
  }

.footer {
    display: grid;
    padding-top: 40px;
    align-content: center;
    text-align: center;
    width: 100%;
    justify-content: center;
    background: #333333;

  }
  .logo-terminos {
    display: flex;
    margin: 0 auto;
    padding-bottom: 20px;
    margin-left: 0px !important;
    width: 100%;
    justify-content: center;
  }
  .svg-contacto{
    display: none;
  }
  .info-contacto {
   display: grid;
  color: white;
  grid-template-columns: 100%;
  text-align: center;
}
.logoTawa{
  justify-content: center;
    display: flex;
    padding-bottom: 20px;
}
.poweredBy{
  text-align: center;
  padding-bottom:5px;
}
}

@media (max-width: 468px) {
  .hero-image {
    background-position: center bottom;
    background-size: contain;
  }

  .section {
    background-size: 250%;
  }

  .section-offer {
    height: 170px;
  }

  .section-footer {
    height: 300px;
  }
}
</style>
<style>
.el-dialog__title {
  font-family: "Roboto", sans-serif;
  font-size: 42px;
  line-height: 52px;
}
.el-checkbox-group {
  font-size: 0;
  display: grid;
  grid-template-columns: 10% 85%;
}
.el-dialog {
  margin-top: 15vh;
  width: 50%;
}
p {
  margin-top: 0;
}
@media (max-width: 468px) {
  .el-dialog__title {
    font-family: "Roboto", sans-serif;
    font-size: 32px;
    line-height: 42px;
  }

  .el-dialog {
    margin-top: 15vh !important;
    width: 90% !important;
  }
}
</style>
